"use client";

import { ErrorComponent } from "@/components/error-component/error-component";
import { Metadata } from "next";

export const metadata: Metadata = {
  title: "Упс... Что-то пошло не так!",
  description: "Упс... Что-то пошло не так!",
  openGraph: {
    title: "Упс... Что-то пошло не так!",
    description: "Упс... Что-то пошло не так!",
  },
};
export default function Custom500({ error }: { error: Error }) {
  return <ErrorComponent error={error} />;
}
